<template>
    <div class="dropdown-action">
        <button
            type="button"
            @click="toggleOpen"
            class="dropdown-action__button"
        >
            <span v-if="buttonText" class="dropdown-action__button__text">{{
                buttonText
            }}</span>
            <span class="material-symbols-outlined">
                {{ open.value ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
            </span>
        </button>

        <transition
            name="dropdown-transition"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
        >
            <div
                v-show="open"
                class="dropdown-actions"
                :class="{ 'open-top': openTop }"
                @click.away="closeMenu"
            >
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script setup>
    import { ref } from "vue";

    // Define props
    const props = defineProps({
        buttonText: {
            type: String,
            default: null,
        },
        openTop: {
            type: Boolean,
            default: false,
        },
    });

    const open = ref(false);

    const toggleOpen = () => {
        open.value = !open.value;
    };

    const closeMenu = () => {
        open.value = false;
    };

    const beforeEnter = (el) => {
        el.style.transform = "scale(0.95)";
        el.style.opacity = 0;
    };

    const enter = (el, done) => {
        el.offsetHeight; // trigger reflow
        el.style.transition =
            "transform 200ms ease-out, opacity 200ms ease-out";
        el.style.transform = "scale(1)";
        el.style.opacity = 1;
        done();
    };

    const leave = (el, done) => {
        el.style.transition = "transform 75ms ease-in, opacity 75ms ease-in";
        el.style.transform = "scale(0.95)";
        el.style.opacity = 0;
        done();
    };
</script>
